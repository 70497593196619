import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  Row,
  ProjectCard,
  UserAvatar,
  Col,
  PaginationComponent,
} from "../../../components/Component";
import { features } from "./ProjectData";
import { MakeApiCall, findUpper } from "../../../utils/Utils";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem, Badge } from "reactstrap";
import FormModal from "./FormModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ProjectCardPage = () => {
  const toggleLg = () => setModalLg(!modalLg);
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };
  const [modalLg, setModalLg] = useState(false);
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    add: false,
    edit: false,
  });
  const [editId, setEditedId] = useState();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(6);
  const [paidValues, setPaidValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [planStates, setPlanStates] = useState({});

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-subscription-plans`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      // setData(responseData?.data);
      if (Array.isArray(responseData?.data)) {
        setData(responseData?.data);
      } else {
        console.error("Expected an array, got", responseData?.data);
        setData([]); // Ensure data is always an array
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure to delete the plan?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.<b><h5>Wait for the page refreshing</h5></b>", "success");
          const formData = new FormData();
          formData.append("id", id);
          MakeApiCall(`api/v1/admin/delete-plan`, formData);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          fetchData();
        }
      });
    } catch (error) {
      console.log("Error deleting plan:", error);
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    durationtype: "m",
    price: "",
    description: 0,
    photos: 0,
    upload_vid: 0,
    upload_yt: "0",
    mixers: 0,
    social_url: 0,
    service: "1",
    contact_frm: 0,
    streaming: "",
    discount: 0,
    discount_price: 0,
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    durationtype: "m",
    price: "",
    description: 0,
    photos: 0,
    upload_vid: 0,
    upload_yt: "0",
    mixers: 0,
    social_url: 0,
    service: "1",
    contact_frm: 0,
    streaming: "",
    discount: 0,
    discount_price: 0,
  });
  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };
  // Function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      durationtype: "m",
      price: "",
      description: 0,
      photos: 0,
      upload_vid: 0,
      upload_yt: "0",
      mixers: 0,
      social_url: 0,
      service: "1",
      contact_frm: 0,
      streaming: "",
    });
  };
  const closeModal = () => {
    setModal({ add: false });
    resetForm();
  };
  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
  };
  const onFormSubmit = async (sData) => {
    setLoading(true);
    const {
      name,
      durationtype,
      price,
      description,
      photos,
      upload_vid,
      upload_yt,
      mixers,
      social_url,
      service,
      contact_frm,
      streaming,
      discount,
      discount_price,
    } = sData;

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("duration_type", durationtype);
      formData.append("price", price);
      formData.append("business_description", description);
      formData.append("photos", photos);
      formData.append("videos", upload_vid);
      formData.append("youtube_link", upload_yt);
      formData.append("mixers", mixers);
      formData.append("social_media_urls", social_url);
      formData.append("service_list", service);
      formData.append("customize_contact_form", contact_frm);
      formData.append("go_live_streaming", streaming);
      formData.append("discount", discount);
      formData.append("discount_price", discount_price);
      const planData = await MakeApiCall(`api/v1/admin/add-plan`, formData);
      if (planData && planData.status) {
        const submittedData = planData.data;
        setData((data) => [submittedData, ...data]);
        toast.success("Plan added successfully");
        fetchData();
      } else {
        setLoading(false);
        console.log("planUser data is invalid or missing required properties");
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
      resetForm();
      setModal({ add: false });
    }
  };
  const onEditSubmit = async (sData) => {
    setLoading(true);
    const {
      id,
      name,
      durationtype,
      price,
      description,
      photos,
      upload_vid,
      upload_yt,
      mixers,
      social_url,
      service,
      contact_frm,
      streaming,
      discount,
      discount_price,
    } = sData;
    console.log(durationtype);
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("name", name);
      formData.append("duration_type", durationtype);
      formData.append("price", price);
      formData.append("business_description", description);
      formData.append("photos", photos);
      formData.append("videos", upload_vid);
      formData.append("youtube_link", upload_yt);
      formData.append("mixes", mixers);
      formData.append("social_media_urls", social_url);
      formData.append("service_list", service);
      formData.append("customize_contact_form", contact_frm);
      formData.append("go_live_streaming", streaming);
      formData.append("discount", discount);
      formData.append("discount_price", discount_price);
      const planData = await MakeApiCall(`api/v1/admin/edit-plan`, formData);
      if (planData && planData.status) {
        const submittedData = planData.data;
        toast.success("Plan updated successfully");
      } else {
        toast.error(planData.message);
        console.log("planUser data is invalid or missing required properties");
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setModal({ edit: false });
      setLoading(false);
      resetForm();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  const onEditClick = (id) => {
    // data.forEach((item) => {
    const item = data.find((item) => item.id === id);
    if (item) {
      setEditFormData({
        id: item.id,
        name: item.name,
        durationtype: item.duration_type,
        price: item.price,
        description: item.business_description,
        photos: item.photos,
        upload_vid: item.videos,
        upload_yt: item.youtube_link,
        mixers: item.mixes,
        social_url: item.social_media_urls,
        service: item.service_list,
        contact_frm: item.customize_contact_form,
        streaming: item.go_live_streaming,
        discount: item.discount,
        discount_price: item.discount_price,
      });
      setModal({ edit: true }, { add: false });
      setEditedId(id);
    }
    // });
  };
  // Pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.length > 0 && data !== undefined ? data.slice(indexOfFirstItem, indexOfLastItem) : [];
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Head title="Project Card"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Create Your Plan</BlockTitle>
              <BlockDes className="text-soft">You have total {data?.length || 0} plan</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>Create New Plan</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {data?.length === 0 ? (
          <div className="fs-2 fw-bolder justify-content-end d-flex flex-column h-225px text-center">
            No Plan is available
          </div>
        ) : loading ? (
          <h1>loading...</h1>
        ) : (
          <Block>
            <Row className="g-gs">
              {currentItems &&
                currentItems.map((item, idx) => {
                  return (
                    <Col sm="6" lg="4" xxl="3" key={item.id}>
                      <ProjectCard>
                        <div className="project-head">
                          <a
                            href="#title"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="project-title"
                          >
                            <UserAvatar className="sq" theme={item.avatarClass} text={findUpper(item.name)} />
                            <div className="project-info">
                              <h5 className="title fs-5">{item.name}</h5>
                            </div>
                          </a>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li onClick={() => onEditClick(item.id)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#edit"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit Plan</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      deleteData(item.id);
                                      // handleAdvanced3()
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Remove Plan</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        {item?.discount === "NaN" || item?.discount === "0" ? (
                          ""
                        ) : (
                          <div className="d-flex w-100 justify-end me-3">
                            <Badge color="primary"> save upto {item?.discount}%</Badge>
                          </div>
                        )}
                        <ul className="team-statistics py-1">
                          <li>
                            {item.duration_type === "y" ? (
                              <span className="text-primary fw-medium">Yearly</span>
                            ) : item.duration_type === "m" ? (
                              <span className="text-primary fw-medium">Month</span>
                            ) : null}
                            <span className="text-primary fw-medium">Plan Duration</span>
                          </li>
                          <li>
                            <span className="d-flex justify-center ">
                              <span
                                className={`fw-medium fs-5  ${
                                  item.discount === "NaN" || item?.discount === "0"
                                    ? "text-primary"
                                    : "text-body text-decoration-line-through"
                                }`}
                              >
                                ${item.price}
                              </span>
                              {item.discount_price === "NaN" || item?.discount === "0" ? (
                                ""
                              ) : (
                                <span className="fw-medium fs-5 text-primary ms-1 shadow px-1">
                                  ${item.discount_price ? parseFloat(item.discount_price).toFixed(2) : "0.00"}
                                </span>
                              )}
                            </span>
                            <span className="text-primary fw-medium">Rate Of Plan</span>
                          </li>
                        </ul>
                        <div className="d-flex justify-content-between mt-3">
                          <div className="fs-4 fw-bold">Plan Features & Their Limits</div>
                        </div>
                        <div className="d-flex flex-column py-1">
                          <div className="d-flex justify-content-between my-1">
                            <span>Business Description :</span>
                            <span>{item?.business_description}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Photos :</span>
                            <span>{item?.photos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Upload Video Limit :</span>
                            <span>{item?.videos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Youtube Videos Links :</span>
                            <span>{item?.youtube_link}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Mixers :</span>
                            <span>{item?.mixes}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Social Media URL's :</span>
                            <span>{item?.social_media_urls}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Service Listings :</span>
                            <span>{item?.service_list}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Cutomized Contact Form :</span>
                            <span>{item?.customize_contact_form}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Go-Live Streaming :</span>
                            <span>{item?.go_live_streaming}</span>
                          </div>
                        </div>
                      </ProjectCard>
                    </Col>
                  );
                })}
            </Row>
            {data?.length === 0 ? null : (
              <div className="mt-3">
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data?.length || 0}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            )}
          </Block>
        )}

        <FormModal
          modal={modal.add}
          modalType="add"
          formData={formData}
          setFormData={setFormData}
          closeModal={closeModal}
          onSubmit={(formData) => onFormSubmit(formData)}
          setLoading={setLoading}
          loading={loading}
        />
        <FormModal
          modal={modal.edit}
          modalType="edit"
          formData={editFormData}
          setFormData={setEditFormData}
          closeModal={closeEditModal}
          onSubmit={onEditSubmit}
          setLoading={setLoading}
          loading={loading}
        />
      </Content>
    </React.Fragment>
  );
};
export default ProjectCardPage;
